import http from '@/axios'
import { IPatientNotification } from '@/interfaces/BaseInterface';

class PatientNotification implements IPatientNotification {
    
    indexPatientNotification() {
        return http.get(`notifications/index`);
    }
    
    readPatientNotification(id: string) {
        return http.post(`notifications/read/${id}`);
    }

    readAllPatientNotification() {
        return http.post(`notifications/readAll`);
    }
}

export default new PatientNotification();
