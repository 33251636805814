import http from '@/axios'

import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

class TestResults {
    
    view(id: string) {
        return http.post(`test-results/view/${id}?${TOKEN_PARAM_STRING}`);
    }
}

export default new TestResults();
