<template>
    <div class="frm-datepicker">
        <DatePicker
            :uid="id"
            v-model="value"
            :disabled="disabled"
            :placeholder="placeholder"
            :timePicker="timePicker"
            :spaceConfirm="spaceConfirm"
            :enableTimePicker="enableTimePicker"
            :enableSeconds="enableSeconds"
            :partialRange="!range"
            :range="range"
            :multiCalendars="multiCalendars"
            :presetRanges="presetRanges"
            :inputClassName="error ? 'frm__date-picker border border-red-300' : 'frm__date-picker'"
            @update:modelValue="updateDate"
            :minDate="minDate"
            :inline="inline"
            :weekPicker="weekPicker"
            :auto-apply="autoApply"
            :weekStart="weekStart"
            :minTime="minTime"
            :maxTime="maxTime"
            :startTime="startTime"
            utc="preserve"
            month-name-format="long"
            week-start="0"
            :markers="markers"
            :disabled-dates="fullyBookedDates"
            :highlight="fullyBookedDates"
            :highlight-disabled-days="highlightDisabledDays"
            :hide-offset-dates="hideOffsetDates"
            :month-change-on-scroll="monthChangeOnScroll"
            :disabled-week-days="disabledWeekDays"
            @update-month-year="handleMonthYear"
        />
            <!-- :highlight="highlight" -->
            <!-- :disabledWeekDays="disabledWeekDays" -->
            <!-- :allowedDates="allowedDates" -->

        <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p>

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="form-error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed, ref } from "vue";
import { addDays } from 'date-fns';

export default {
    components: {
        DatePicker,
        // ExclamationCircleIcon,
    },
    props: {
        appointments: {
            default: Array,
            required: false,
        },
        error: {
            type: String,
            default: null,
        },
        modelValue: {
            default: null,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        timePicker: {
            type: Boolean,
            default: false,
        },
        enableTimePicker: {
            type: Boolean,
            default: false,
        },
        enableSeconds: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        multiCalendars: {
            type: Boolean,
            default: false,
        },
        presetRanges: {
            default: [],
        },
        spaceConfirm: {
            type: Boolean,
            default: true,
        },
        minDate: {
            type: Date,
            default: null,
        },
        // highlight: {
        //     type: Array,
        //     default: [],
        // },
        // disabledWeekDays: {
        //     type: Array,
        //     default: [],
        // },
        inline: {
            type: Boolean,
            default: true,
        },
        weekPicker: {
            type: Boolean,
            default: false,
        },
        autoApply: {
            type: Boolean,
            default: true,
        },
        // allowedDates: {
        //     type: Array,
        //     default: [],
        // },
        weekStart: {
            type: Number,
            default: 1,
        },
        minTime: {
            default: null,
        },
        maxTime: {
            default: null,
        },
        startTime: {
            default: null,
        },
        fullyBookedDates : {
            type: Array,
            default: () => {
                return [];
            }
        },
        highlightDisabledDays: {
            type: Boolean,
            default: false,
        },
        hideOffsetDates: {
            type: Boolean,
            default: false,
        },
        monthChangeOnScroll: {
            type: Boolean,
            default: false,
        },
        helper: {
            type: String,
            default: null,
        },
        disabledWeekDays: {
            type: Array,
            default: () => {
                return [];
            }
        },
    },
    setup(props, { emit }) {
        const value = ref(props.modelValue);

        const updateDate = (value) => {
            emit("update:modelValue", value);
        };

        const date = ref(new Date());

        // const markers = computed(() => {
        //     if(!props.appointments) return [];
        //     return props.appointments?.map( (i) => {
        //         return {
        //             date: new Date(i.booking_created_date),
        //             type: 'dot',
        //             tooltip: [{ text: i.status }], 
        //         }
        //     })
        // })

        // @TODO - add upcoming appointments once hybrain API is fixed.
        const markers = ref([
            // {
            //     date: addDays(new Date(), 1),
            //     type: 'dot',
            //     tooltip: [{ text: 'Dot with tooltip' }],
            // },
            // {
            //     date: addDays(new Date(), 3),
            //     type: 'dot',
            // },
        ])

        const handleMonthYear = ({ instance, month, year }) => {
            emit("updateMonth", { month, year });
        }

        return {
            updateDate,
            value,
            date,
            markers,
            handleMonthYear
        };
    },
};
</script>
