import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import StyleSheet from "@/views/StyleSheet.vue";

// Auth pages
import Login from "@/views/auth/LoginPage.vue";
import SignUp from "@/views/auth/SignUp.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ForgotResetPassword from "@/views/auth/ResetPassword.vue";

// Dashboard
import OverviewIndex from "@/views/dashboard/overview/IndexPage.vue";
import TestResultsIndex from "@/views/dashboard/test-results/IndexPage.vue";
import TestResultsView from "@/views/dashboard/test-results/ViewPage.vue";
import AppointmentIndex from "@/views/dashboard/appointments/IndexPage.vue";
import AppointmentShow from "@/views/dashboard/appointments/ShowPage.vue";
import SoftBookingAppointmentShow from "@/views/dashboard/appointments/ShowSoftBookingPage.vue";
import ScheduleLogsIndex from "@/views/dashboard/appointments/schedule-logs/IndexPage.vue";
import MedicalQuestionnaireIndex from "@/views/dashboard/appointments/medical-questionnaire/IndexPage.vue";
import MedicalRecordIndex from "@/views/dashboard/medical-record/IndexPage.vue";
import NotificationIndex from "@/views/dashboard/notification/IndexPage.vue";

// Profile
import AccountDetails from "@/views/profile/AccountDetails.vue";
import EditAccountDetails from "@/views/profile/EditAccountDetails.vue";
import ChangePassword from "@/views/profile/ChangePassword.vue";

// Booking
import BookingPage from "@/views/bookings/BookingPage.vue";
import ChoosePackage from "@/views/bookings/ChoosePackage.vue";
import SelectDateAndTime from "@/views/bookings/SelectDateAndTime.vue";
import ConfirmBooking from "@/views/bookings/ConfirmBooking.vue";


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login',
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/reset-password',
        name: 'ForgotResetPassword',
        component: ForgotResetPassword,
        meta:{
            needsAuth: false
        }
    },

    {
        path: '/notifications',
        name: 'NotificationIndex',
        component: NotificationIndex,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/overview',
        name: 'OverviewIndex',
        component: OverviewIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/test-results',
        name: 'TestResultsIndex',
        component: TestResultsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/test-results/show/:laboratory_id',
        name: 'TestResultsView',
        component: TestResultsView,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/appointment',
        name: 'AppointmentIndex',
        component: AppointmentIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/appointment/show/:id',
        name: 'AppointmentShow',
        component: AppointmentShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/soft-booking-appointment/show/:id',
        name: 'SoftBookingAppointmentShow',
        component: SoftBookingAppointmentShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/schedule-logs/:bookingId',
        name: 'ScheduleLogsIndex',
        component: ScheduleLogsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/medical-questionnaire/:bookingId',
        name: 'MedicalQuestionnaireIndex',
        component: MedicalQuestionnaireIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/medical-record',
        name: 'MedicalRecordIndex',
        component: MedicalRecordIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/booking',
        name: 'BookingPage',
        component: BookingPage,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/booking/choose-package',
        name: 'ChoosePackage',
        component: ChoosePackage,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/booking/select-date-and-time',
        name: 'SelectDateAndTime',
        component: SelectDateAndTime,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/booking/confirmation',
        name: 'ConfirmBooking',
        component: ConfirmBooking,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/account-details',
        name: 'AccountDetails',
        component: AccountDetails,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/account-details/edit',
        name: 'EditAccountDetails',
        component: EditAccountDetails,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/stylesheet',
        name: 'Stylesheet',
        component: StyleSheet
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
