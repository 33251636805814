import http from '@/axios'
import { IBranch } from '@/interfaces/BaseInterface';

class Branch implements IBranch {
    
    editBranch(id: string) {
        return http.get(`branches/edit/${id}`);
    }
}

export default new Branch();
