import http from '@/axios'

class locationService {

    fetchRegions() : Promise<JSON> {
        return http.get(`locations/regions`);
    }

    fetchProvinces() : Promise<JSON> {
        return http.get(`locations/provinces`);
    }

    fetchCities(params: string) : Promise<JSON> {
        return http.get(`locations/cities?${params}`);
    }

    fetchBarangays(params: string) : Promise<JSON> {
        return http.get(`locations/barangay?${params}`);
    }
}

export default new locationService();
