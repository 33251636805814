import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import * as Sentry from "@sentry/vue";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Custom Font */
// import './assets/fonts/red-hat/stylesheet.scss';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';

/* Custom CSS */
import './assets/scss/app.scss';

const pinia = createPinia();
const app = createApp(App)
  .use(pinia)
  .use(IonicVue, {
    mode: 'ios',
  });

Sentry.init({
  app,
  dsn: "https://ee5a8f190c6542d191e1902d37a28081@sentry.praxxys.ph/54",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [process.env.VUE_SENTRY_TRACING_ORIGIN_URL, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});