interface IdaysOfWeek {
    label: string,
    value: number | string,
    start_time?: Date,
    end_time?: Date,
}

const daysOfTheWeek : IdaysOfWeek[] = [
    {
        label: 'Sunday',
        value: 0
    },
    {
        label: 'Monday',
        value: 1
    },
    {
        label: 'Tuesday',
        value: 2
    },
    {
        label: 'Wednesday',
        value: 3
    },
    {
        label: 'Thursday',
        value: 4
    },
    {
        label: 'Friday',
        value: 5
    },
    {
        label: 'Saturday',
        value: 6
    },
   
]

export { daysOfTheWeek }