<template>
    <div class="frm-datepicker">
        <label :for="id" class="block text-sm text-gray-900 mb-1">{{
            label
        }}</label>
        <DatePicker
            :uid="id"
            v-model="value"
            :disabled="disabled"
            :placeholder="placeholder"
            :timePicker="timePicker"
            :spaceConfirm="spaceConfirm"
            :enableTimePicker="enableTimePicker"
            :enableSeconds="enableSeconds"
            :partialRange="!range"
            :range="range"
            :multiCalendars="multiCalendars"
            :presetRanges="presetRanges"
            inputClassName="frm__date-picker"
            @update:modelValue="updateDate"
            :minDate="minDate"
            :inline="inline"
            :weekPicker="weekPicker"
            :autoApply="autoApply"
            :weekStart="weekStart"
            :minTime="minTime"
            :maxTime="maxTime"
            :startTime="startTime"
            utc="preserve"
            :auto-apply="true"
            month-name-format="long"
            week-start="0"
            :markers="markers"
            :disabled-dates="fullyBookedDates"
            :highlight="fullyBookedDates"
            :highlight-disabled-days="highlightDisabledDays"
            :hide-offset-dates="hideOffsetDates"
            :month-change-on-scroll="monthChangeOnScroll"
            :format="format"
            @update-month-year="handleMonthYear"
        />
            <!-- :highlight="highlight" -->
            <!-- :disabledWeekDays="disabledWeekDays" -->
            <!-- :allowedDates="allowedDates" -->
    </div>
</template>

<script>
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { ref } from "vue";

export default {
    components: {
        DatePicker,
        // ExclamationCircleIcon,
    },
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        timePicker: {
            type: Boolean,
            default: false,
        },
        enableTimePicker: {
            type: Boolean,
            default: false,
        },
        enableSeconds: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        multiCalendars: {
            type: Boolean,
            default: false,
        },
        presetRanges: {
            default: [],
        },
        spaceConfirm: {
            type: Boolean,
            default: true,
        },
        minDate: {
            type: Date,
            default: null,
        },
        // highlight: {
        //     type: Array,
        //     default: [],
        // },
        // disabledWeekDays: {
        //     type: Array,
        //     default: [],
        // },
        inline: {
            type: Boolean,
            default: false,
        },
        weekPicker: {
            type: Boolean,
            default: false,
        },
        autoApply: {
            type: Boolean,
            default: true,
        },
        // allowedDates: {
        //     type: Array,
        //     default: [],
        // },
        weekStart: {
            type: Number,
            default: 1,
        },
        minTime: {
            default: null,
        },
        maxTime: {
            default: null,
        },
        startTime: {
            default: null,
        },
        fullyBookedDates : {
            type: Array,
            default: () => {
                return [];
            }
        },
        highlightDisabledDays: {
            type: Boolean,
            default: false,
        },
        hideOffsetDates: {
            type: Boolean,
            default: false,
        },
        monthChangeOnScroll: {
            type: Boolean,
            default: false,
        },
        format: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const value = ref(props.modelValue);

        const updateDate = (value) => {
            emit("update:modelValue", value);
        };

        const handleMonthYear = ({ instance, month, year }) => {
            emit("updateMonth", { month, year });
        }

        return {
            updateDate,
            value,
            handleMonthYear
        };
    },
};
</script>
<style lang="scss">
    .dp__cell_inner {
        margin: auto;
    }
</style>
