import http from '@/axios'
import Storage from '@/helpers/storage';
import { computed } from 'vue';
import { IAuth } from '@/interfaces/BaseInterface';

class AuthService implements IAuth {
    private readonly storage;

    public constructor(getStorage = () => new Storage()) {
        this.storage = getStorage();
    }

    public checkMode(token:string|null = null): boolean {
        
        if ('VUE_APP_MODE' in process.env && process.env.VUE_APP_MODE == 'maintenance') {
            return process.env.VUE_APP_MODE_KEY == token ? false : true;
        }

        return false
    }

    async login(email: string, password: string) {
        return http
            .post("auth/login", {
                email,
                password
            })
    }

    async logout() {
        return http
            .post("auth/logout")
            .then(() => {
                this.storage.clearItems(['patient', 'access_token', 'hybrain_token'])
                window.location.replace('/login')
            })
    }

    async forgotPassword(email: string) {
        return http
            .post("auth/forgot-password", {
                email,
            })
    }

    async changePassword(payload: object) {
        return http
            .post("auth/change-password", payload)
    }

    register(data: any) {
        return http.post("signup", data);
    }

    isAuthenticated() {
        const authToken = computed(() => this.storage.get('access_token'));
        return authToken.value ? true : false;
    }

    redirectToLogin() {
        this.storage.clearItems(['patient', 'access_token'])
        window.location.replace('/login')
    }

    getHybrainTokenObject() : { token: string | null, query: string } {
        const getToken = this.storage.get("hybrain_token");
        return {
            query: 'token',
            token: getToken
        }
    }

    getHybrainTokenQueryString() : string {
        const getToken = this.storage.get("hybrain_token");
        return `token=${getToken}`;
    }
}
export default new AuthService();